import React from "react"

import Layout from "../../components/layout"
import PartnerList from "../../components/PartnerList"

const PartnersPage = () => {
  return (
    <Layout>
      <PartnerList />
    </Layout>
  )
}

export default PartnersPage
