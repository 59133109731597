import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const PartnerCard = ({ partner }) => {
  return (
    <div className="sm:max-w-md overflow-hidden border-b border-gray-600">
      <Link to={partner.fields.slug}>
        <PartnerImage image={partner.frontmatter.image} />
        <div className="px-6 py-2">
          <div className="mb-2 text-sm">{partner.frontmatter.name}</div>
          {/* <p className="text-gray-700 text-base">
            {partner.frontmatter.description}
          </p> */}
        </div>
      </Link>
    </div>
  )
}

const PartnerImage = ({ image }) => {
  const imageStyle = {
    width: "100%",
    height: 80,
    overflow: "hidden",
  }
  return <Img style={imageStyle} fluid={image.childImageSharp.fluid} />
}

const PartnerList = ({ data }) => {
  const { edges: partners } = data.allMarkdownRemark
  return (
    <div className="grid grid-cols-1 grid-cols-2 md:grid-cols-4 gap-6">
      {partners &&
        partners.map(({ node: partner }, i) => (
          <PartnerCard key={partner.id} partner={partner} />
        ))}
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "partner-page" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                name
                description
                image {
                  id
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <PartnerList data={data} />}
  ></StaticQuery>
)
